
//react
import { useEffect, useState } from "react";
import { useParams           } from "react-router";
import {Card, Row, Col, Container, Spinner} from "react-bootstrap";
//date picker
import DatePicker, { DayValue, DayRange, Day } from '@hassanmojab/react-modern-calendar-datepicker';
//vade
import Api        from "../Api/Api";
import { ICam }   from "../Api/ICam";
import Deployment, {IDeployment} from "../Api/Deployment";
//Components
import {AccuracyPageCamTable} from "./AccuracyPageCamTable";
import {LaneTypeSelector} from "./AccuracyPageTopSelectors";
import {AccuracyMainTable, DataRow} from "./AccuracyPageMainTable";
import {
    AccuracyDataForDay,
    getCurrWeekRange,
    AggregatedAccuracyMetrics,
} from "./AccuracyPageHelpers"
import ILane from "../Api/ILane";
import CreatableSelect from "react-select/creatable";



export default function AccuracyPageMain() {
    const [ allDepls, setAllDepls] = useState<IDeployment[]>([]);
    const allCameras: ICam[] = [...allDepls.map((depl: IDeployment) => depl.cameras)].flat(1)
    const allLanes: ILane[] = [...allDepls.map((depl: IDeployment) => depl.lanes)].flat(1)
    const [selectedCamIds, setSelectedCamIds] = useState<Set<string>>( new Set())
    //date picker
    const [dayRange, setDayRange] = useState<DayRange>(getCurrWeekRange())
    // Visual States
    const [deplsLoaded, setDeplsLoaded] = useState(false)
    const [accuracyData, setAccuracyData] = useState<any>(null)

    const [domainInput, setDomainInput] = useState({value: "matchmaker", label: "matchmaker"})
    const [isLoadingData, setIsLoadingData] = useState(false)
    const domain = domainInput.value


    //hooks
    useEffect( () => {
        loadDeployments()
    }, [] );

    useEffect( () => {
        // generateJobRows()
        getAccuracyData()
    }, [selectedCamIds, dayRange, domainInput] );


    function getCvProcessingParams(){
        if (!dayRange.to || !dayRange.from || selectedCamIds.size === 0){
            return {
                camIds: [],
                selectedCams: [],
                start : new Date(),
                end : new Date()
            }
        }
        let selectedCams = allCameras.filter((cam) => selectedCamIds.has(cam.uuid))
        const camIds: string[] = selectedCams.map((cam) => cam.uuid)
        let startDate: Date = new Date(dayRange.from.year, dayRange.from.month - 1, dayRange.from.day)
        let endDate: Date =   new Date(dayRange.to.year, dayRange.to.month - 1, dayRange.to.day)
        return {
            camIds: camIds,
            selectedCams: selectedCams,
            start: startDate,
            end: endDate
        }
    }

    async function loadDeployments(){
        const apiReq = await Api.getDeployments()
        const json = await apiReq.json()
        let deployments: IDeployment[] = json.deployments
        deployments = deployments.sort(((d1,d2) => d1.name < d2.name ? -1 : 1));
        setAllDepls(deployments)
        setDeplsLoaded(true)
    }

    async function getAccuracyData(){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){ return }
        setIsLoadingData(true)
        const lanesToGetMetricsFor = allLanes.filter((lane) => selectedCamIds.has(lane.camera_uuid))
        const laneIds: string[] = lanesToGetMetricsFor.map((lane) => lane.uuid)
        const startDate: Date = params.start
        const endDate: Date =  params.end
        const accuracyApiReq = await Api.getAccuracyForLaneAndDay(laneIds, startDate, endDate, domain)
        if (accuracyApiReq.status === 500) {
            alert("Accuracy function crashed with 500 resp code")
            setAccuracyData(null)
            setIsLoadingData(false)
            return
        }
        if (accuracyApiReq.status === 204) {
            console.log("Accuracy endpoint returned 204 - no ground truth in your search range")
            setAccuracyData(null)
            setIsLoadingData(false)
            return
        }
        const accuracyJson = await accuracyApiReq.json()
        if (accuracyApiReq.status != 200){
            alert(accuracyJson)
            setAccuracyData(null)
            setIsLoadingData(false)
            return
        }
        const accuracyData: AccuracyDataForDay[] = accuracyJson.accuracy
        // calculateAccuracy(accuracyData)
        const dataRows: DataRow[] = []
        let rowCount = 0
        accuracyData.forEach((accPoint) => {
            const newRow: DataRow = {
                id: rowCount,
                cameraId: accPoint.camera_uuid,
                day: accPoint.day,
                laneId: accPoint.lane_uuid,
                sfs: accPoint.sfs,
                noSfs: accPoint.no_sfs
            }
            rowCount += 1
            dataRows.push(newRow)
        })
        const aggregatedMetrics: AggregatedAccuracyMetrics = accuracyJson.aggregated_accuracy
        const aggregatedQuestionAcc: AggregatedAccuracyMetrics = accuracyJson.task_accuracy
        const aggregatedRawAcc: AggregatedAccuracyMetrics = accuracyJson.raw_accuracy
        const cleanedUpAccuracy = {
            tableData: dataRows,
            aggregatedAccuracy: aggregatedMetrics,
            questionAccuracy: aggregatedQuestionAcc,
            rawAccuracy: aggregatedRawAcc
        }
        setAccuracyData(cleanedUpAccuracy)
        setIsLoadingData(false)
    }


    if (!deplsLoaded){
        return (
            <Card>
                <Card.Title>Loading...</Card.Title>
                <Spinner/>
            </Card>
        )
    }

    function ConditionalTable(){
        if (isLoadingData){
            return <h6>Loading Accuracy Data...</h6>
        }
        if (accuracyData){
            return <AccuracyMainTable
                tableData={accuracyData.tableData}
                aggregatedAccuracy={accuracyData.aggregatedAccuracy}
                questionAccuracy={accuracyData.questionAccuracy}
                rawAccuracy={accuracyData.rawAccuracy}
            />
        }else{
            return <h6>No Ground Truth Defined. Please Change Your Camera/Date Selection</h6>
        }
    }

    return <Container fluid>
        <Row>
            <Col className="bg-light" sm={2}>
                <Card>
                    <Card.Title>Camera Picker</Card.Title>
                </Card>
                <AccuracyPageCamTable deployments={allDepls}
                                   selectedCameraIds={selectedCamIds}
                                   setCamIdsFunction={setSelectedCamIds}
                />
            </Col>
            <Col sm={10}>
                <Row>
                    <div>
                        <CreatableSelect
                            placeholder="domain"
                            defaultValue={domainInput}
                            isMulti={false}
                            onChange={(e: any) => {setDomainInput(e)}}
                        />
                    </div>
                    <Col sm={9}>
                        <DatePicker  value={dayRange} onChange={setDayRange} />
                    </Col>
                </Row>
                <ConditionalTable/>
                <br/>
            </Col>
        </Row>
    </Container>
}
