
//react
import { useEffect, useState } from "react";
import {Badge, Card, Table, Form, ProgressBar} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import {getTimelinesToBeRun} from "./CVControllerHelpers"
import {CameraJob, TimeFrame} from "./CVControllerMainTable";

export interface CvRunPanelProgress{
    parkingVisProgress: number
    stayGenProgress: number
    taskGenProgress: number
    matchmakerProgress: number
}

export interface CVRunPanelConfig {
    parkingVisConfig: {
        selected: boolean,
    }
    stayGenConfig: {
        selected: boolean,
        domain: string
    }
    taskGenConfig: {
        selected: boolean,
        domain: string,
        ommit_single_frame_stays: boolean
        only_single_frame_stays: boolean
        // delete_previous: boolean
    }
    matchmakerConfig: {
        selected: boolean,
        inputDomain: string,
        outputDomain: string,
        add_default_starts: boolean,
        add_default_ends: boolean
    }
}

export function defaultCvPanelConfig(){
    const defaultConfig: CVRunPanelConfig = {
        parkingVisConfig: { selected: false},
        stayGenConfig: {
            selected: false,
            domain: "raw"
        },
        taskGenConfig: {
            selected: false,
            domain: "raw",
            ommit_single_frame_stays: false,
            only_single_frame_stays: false,
        },
        matchmakerConfig: {
            selected: false,
            inputDomain: "raw",
            outputDomain: "matchmaker",
            add_default_starts: false,
            add_default_ends: false
        }
    }
    return defaultConfig
}


export function CVRunPanel(props: {config: CVRunPanelConfig, setConfigFunc: any, setStatusFunc: any, progress: CvRunPanelProgress | undefined, camJobs: CameraJob[], runFunction: any}) {
    // const [type, setType]: any = useState('');
    // const config: CVRunPanelConfig = props.config
    const { register, handleSubmit,  formState: { errors },  setValue } = useForm<CVRunPanelConfig>({
        defaultValues: defaultCvPanelConfig()
    });
    const progress = props.progress
    const timeFramesToRun = getTimelinesToBeRun(props.camJobs)

    const onSubmit = (data: CVRunPanelConfig) => {
        console.log(data)
        props.setConfigFunc(data)
        props.setStatusFunc("starting")
        props.runFunction(data)
        // alert(JSON.stringify(data));
    };

    return (
        <Card>
            <Card.Header as="h5">Run Pannel</Card.Header>
            <Card.Header>
                {timeFramesToRun.length} jobs to run
            </Card.Header>
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/*<input type="checkbox" {...register("parkingVisConfig.selected")} />*/}
                    {/*<label>Run Parking Vision</label>*/}
                    {/*<ProgressBar now={progress?.parkingVisProgress} />*/}
                    {/*<hr/>*/}
                    {/*<br/>*/}


                    <input type="checkbox" {...register("stayGenConfig.selected")} />
                    <label>Run Stay Generator</label>
                    <br/>
                    <label>--Stay Generator Domain</label>
                    <input type="text" {...register("stayGenConfig.domain")} />
                    {/*<ProgressBar now={progress?.stayGenProgress} />*/}
                    <hr/>
                    <br/>



                    <input type="checkbox" {...register("taskGenConfig.selected")} />
                    <label>Run Task Generator</label>
                    <br/>
                    <label>--Task Generator Domain</label>
                    <input type="text" {...register("taskGenConfig.domain")} />
                    <br/>
                    <input type="checkbox" {...register("taskGenConfig.ommit_single_frame_stays")} />
                    <label>--Omit Single Frame Stays</label>
                    <br/>
                    <input type="checkbox" {...register("taskGenConfig.only_single_frame_stays")} />
                    <label>--Only Single Frame Stays</label>
                    {/*<ProgressBar now={progress?.taskGenProgress} />*/}
                    <hr/>
                    <br/>


                    <input type="checkbox" {...register("matchmakerConfig.selected")} />
                    <label>Run Match Maker</label>
                    <br/>
                    <label>--Match Maker Input Domain</label>
                    <input type="text" {...register("matchmakerConfig.inputDomain")} />
                    <br/>
                    <label>--Match Maker Output Domain</label>
                    <input type="text" {...register("matchmakerConfig.outputDomain")} />
                    <br />
                    <input type="checkbox" {...register("matchmakerConfig.add_default_starts")} />
                    <label>--Add Default Starts</label>
                    <br/>
                    <input type="checkbox" {...register("matchmakerConfig.add_default_ends")} />
                    <label>--Add Default Ends</label>
                    <br/>
                    {/*<ProgressBar now={progress?.matchmakerProgress} />*/}
                    <hr/>
                    <br/>

                    <input type="submit" />
                </form>
            </Card.Body>
        </Card>
    );

}
