//react
import { useEffect, useState } from "react";
import { Link, matchPath, useLocation, useMatch, useParams } from "react-router-dom";

//vade
import Api      from "./Api/Api";
import { ICam } from "./Api/ICam";
import DateUtil from "./Util/DateUtil";
import Fmt      from "./Util/Fmt";

export default function CamListPage() {
    const location = useLocation();
    const params   = useParams();
    const [items, setItems] = useState<Array<ICam>>( [] );

    //only show unregistered cams
    function unregistered( cam: ICam ) {
        if(  cam.imei.includes( "FAKE-" )      ) { return false; }
        if( !cam.imei.includes( "OCULi" )      ) { return false; }
        if(  cam.deployment_uuid === undefined ) { return true;  }
        if(  cam.deployment_uuid === null      ) { return true;  }
        if(  cam.deployment_uuid === ""        ) { return true;  }
        return false;
    }

    let depId = "";
    function depFilter( cam: ICam ) {
        if( cam.deployment_uuid == depId ) {
            return true;
        }
        return false;
    }

    function all( cam: ICam ) {
        if(  cam.imei.includes( "FAKE-" )　) { return false; }
        return true;
    　}

    let title      = "All Cameras";
    let filt       = all; //the default
    let deplMatch  = useMatch("/dep/:depId/cam/");
    let unregMatch = useMatch("/cam/unreg/");
    if( deplMatch ) {
        filt = depFilter;
        depId = deplMatch.params.depId!; //see depFilter func for var decl
        title = "Cameras in Deployment " + deplMatch.params.depId;
    }
    else if( unregMatch ) {
        filt  = unregistered;        
        title = "Unregistered Cameras";
    }

    //get our data from the backend
    useEffect( () => {
        Api.getCams( 1, 2000 ).then( resp => resp.json() )
                              .then( json => setItems( json.cameras.filter( filt ) ) );
    }, [1] ); //i just want to run this once, and never again

    const listItems = items.map( ( item: any ) => <tr key={item.uuid}>
                <td className="col-md-auto">{DateUtil.toSmallDateStr( new Date( item.time_created ) )}</td>
                <td className="col-md-auto">{item.imei}</td>
                <td className="col-md-auto">{Fmt.toActiveInactive( item.is_active ) }</td>
                <td className="col-md-auto" width="1">
                    <Link to={"/cam/" + item.uuid}>
                        <button className='btn btn-primary'>view</button>
                    </Link>
                </td>
                <td className="col-md-auto" width="1">
                    <Link to={"/cam/" + item.uuid + "/edit"}>
                        <button className='btn btn-primary'>edit</button>
                    </Link>
                </td>
            </tr>
    );

    return (<>
        <div className="m-3 row align-middle">
            <h3 className="">{title}</h3>
            <table className="table w-auto table-striped">
            <thead>
                <tr>
                    <td className="col-md-auto">Created     </td>
                    <td className="col-md-auto">IMEI        </td>                    
                    <td className="col-md-auto">Status      </td>
                    <td></td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {listItems}
            </tbody>
        </table>
        </div>
    </> );
}