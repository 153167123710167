
//react
import { useEffect, useState } from "react";
import { useParams           } from "react-router";
import {Card, Row, Col, Container, Spinner} from "react-bootstrap";
//date picker
import DatePicker, { DayValue, DayRange, Day } from '@hassanmojab/react-modern-calendar-datepicker';
//vade
import Api        from "../Api/Api";
import { ICam }   from "../Api/ICam";
import Deployment, {IDeployment} from "../Api/Deployment";
//Components
import {CVControllerTable} from "./CVControllerCamTable";
import {LaneTypeSelector} from "./CVControllerTopSelectors";
import {CVMainTable, StayTimeFrameVal, TimeFrame} from "./CVControllerMainTable";
import {CameraJob} from "./CVControllerMainTable"
import {
    getDaysArray,
    CVDataForDay,
    isSameDay,
    calculateProgress,
    getTimelinesToBeRun,
    getCurrWeekRange
} from "./CVControllerHelpers"
import {CVRunPanel, CVRunPanelConfig, defaultCvPanelConfig, CvRunPanelProgress} from "./CVControllerRunPanel";
import ILane from "../Api/ILane";
import {DateTime} from "luxon";
import {ITrace} from "../Api/ITrace";


export default function CVControllerMain() {
    const [ allDepls, setAllDepls] = useState<IDeployment[]>([]);
    const allCameras: ICam[] = [...allDepls.map((depl: IDeployment) => depl.cameras)].flat(1)
    const allLanes: ILane[] = [...allDepls.map((depl: IDeployment) => depl.lanes)].flat(1)
    const [selectedCamIds, setSelectedCamIds] = useState<Set<string>>( new Set())
    const [selectedLaneType, setSelectedLaneType] = useState<Set<string>>( new Set())
    const [cameraJobs, setCameraJobs] = useState<CameraJob[]>([])
    const [runState, setRunState] = useState<string | undefined>(undefined)
    const [runConfig, setRunConfig] = useState<CVRunPanelConfig>(defaultCvPanelConfig())
    const runProgress: CvRunPanelProgress = calculateProgress(cameraJobs)
    //date picker

    const [dayRange, setDayRange] = useState<DayRange>(getCurrWeekRange())
    // Visual States
    const [deplsLoaded, setDeplsLoaded] = useState(false)
    //routing
    const params = useParams();


    //hooks
    useEffect( () => {
        Api.getDeployments().then( resp => resp.json() )
            .then( json => {
                let deployments: IDeployment[] = json.deployments
                deployments = deployments.sort(((d1,d2) => d1.name < d2.name ? -1 : 1));
                setAllDepls(deployments)
                setDeplsLoaded(true)
            } );
    }, [params.camId] );

    useEffect( () => {
        generateJobRows()
    }, [selectedCamIds, selectedLaneType, dayRange] );

    useEffect(() => {
        updateCamJobLanes()
    }, [selectedLaneType])

    function updateCamJobLanes(){
        const camJobCopy = [...cameraJobs]
        camJobCopy.forEach((job) => {
            const cam = job.camera
            const cameraLanes: ILane[] = allLanes.filter((lane) => lane.camera_uuid === cam.uuid)
            const cameraLanesToInclude = cameraLanes.filter((lane) => !selectedLaneType.has(lane.lane_type))
            const cameraLanesToExclude = cameraLanes.filter((lane) => selectedLaneType.has(lane.lane_type))
            job.lanesToInclude = cameraLanesToInclude
            job.lanesToExclude = cameraLanesToExclude
        })
        updateCamJobs(camJobCopy)
    }

    function getCvProcessingParams(){
        if (!dayRange.to || !dayRange.from || selectedCamIds.size === 0){
            return {
                camIds: [],
                selectedCams: [],
                start : new Date(),
                end : new Date()
            }
        }
        let selectedCams = allCameras.filter((cam) => selectedCamIds.has(cam.uuid))
        const camIds: string[] = selectedCams.map((cam) => cam.uuid)
        let startDate: Date = new Date(dayRange.from.year, dayRange.from.month - 1, dayRange.from.day)
        let endDate: Date =   new Date(dayRange.to.year, dayRange.to.month - 1, dayRange.to.day)
        return {
            camIds: camIds,
            selectedCams: selectedCams,
            start: startDate,
            end: endDate
        }
    }

    async function generateJobRows(){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){
            setCameraJobs([])
            return
        }
        const selectedCams: ICam[] = params.selectedCams
        const camIds: string[] = params.camIds
        const startDate: Date = params.start
        const endDate: Date =  params.end

        console.log("Generating Blank Camera Job Rows...")

        let dates: Date[] = getDaysArray(startDate, endDate)
        var camJobs:CameraJob[] = []
        selectedCams.forEach((cam) => {
            let timeFrames:TimeFrame[] = []
            let cameraLanes: ILane[] = allLanes.filter((lane) => lane.camera_uuid === cam.uuid)
            let cameraLanesToInclude = cameraLanes.filter((lane) => !selectedLaneType.has(lane.lane_type))
            let cameraLanesToExclude = cameraLanes.filter((lane) => selectedLaneType.has(lane.lane_type))
            dates.forEach((date) => {
                let camTimeFrame: TimeFrame = {
                    day: date,
                    tracesOnDay: undefined,
                    staysOnDay: undefined,
                    tasksOnDay: undefined,
                    completedTasksOnDay: undefined,
                    visOnDay: undefined,
                    parkingVisionResp: undefined,
                    stayGenResp: undefined,
                    taskGenResp: undefined,
                    matchmakerResp: undefined,
                    currTask: undefined,
                    selected: false
                }
                timeFrames.push(camTimeFrame)
            })
            let newJob: CameraJob = {
                camera: cam,
                timeFrames: timeFrames,
                lanesToInclude: cameraLanesToInclude,
                lanesToExclude: cameraLanesToExclude,
                currJobProgress: undefined,
                currJobTask: undefined
            }
            camJobs.push(newJob)
        })
        setCameraJobs(camJobs)
        const camJobsWithTraceData = await fillTraceData(camJobs)
        if (camJobsWithTraceData){
            camJobs = camJobsWithTraceData
        }

        const camJobsWithVisData = await fillVisData(camJobs)
        if (camJobsWithVisData){
            camJobs = camJobsWithVisData
        }
        const camJobsWithStayData = await fillStayData(camJobs)
        if (camJobsWithStayData){
            camJobs = camJobsWithStayData
        }
        await fillTaskData(camJobs)
    }

    async function fillTraceData(oldCamJobs: CameraJob[]){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){ return }
        console.log("Getting trace metrics for cameras...")
        const camIds: string[] = params.camIds
        const startDate: Date = params.start
        const endDate: Date =  params.end
        const tracesApiReq = await Api.getCVStatsByCameras(camIds, startDate, endDate, "traces")
        const tracesProcessingJson = await tracesApiReq.json()
        const tracesProcessingData: CVDataForDay[] = tracesProcessingJson.trace_stats
        let camJobsNew: CameraJob[] = [...oldCamJobs]
        camJobsNew.forEach((job) => {
            const traceDataForCam:CVDataForDay[] = tracesProcessingData.filter((data) => data.camera_uuid === job.camera.uuid)
            if (traceDataForCam === undefined){
                job.timeFrames.forEach((timeframe) => timeframe.tracesOnDay = null)
                return
            }
            job.timeFrames.forEach((timeframe) => {
                const visDataForTimeFrame = traceDataForCam.find((dataForDay) => isSameDay(timeframe.day, new Date(dataForDay.date)))
                if (visDataForTimeFrame === undefined){
                    timeframe.tracesOnDay = null
                    return
                }
                timeframe.tracesOnDay = visDataForTimeFrame.traces ?? null
            })
        })
        setCameraJobs(camJobsNew)
        return camJobsNew
    }

    async function fillVisData(oldCamJobs: CameraJob[]){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){ return }
        console.log("Getting vis metrics for cameras...")
        const camIds: string[] = params.camIds
        const startDate: Date = params.start
        const endDate: Date =  params.end
        const visApiReq = await Api.getCVStatsByCameras(camIds, startDate, endDate, "vis")
        const visProcessingJson = await visApiReq.json()
        const visProcessingData: CVDataForDay[] = visProcessingJson.vis_processing_stats
        let camJobsNew: CameraJob[] = [...oldCamJobs]
        camJobsNew.forEach((job) => {
            const visDataForCam:CVDataForDay[] = visProcessingData.filter((data) => data.camera_uuid === job.camera.uuid)
            if (visDataForCam === undefined){
                job.timeFrames.forEach((timeframe) => timeframe.visOnDay = null)
                return
            }
            job.timeFrames.forEach((timeframe) => {
                const visDataForTimeFrame = visDataForCam.find((dataForDay) => isSameDay(timeframe.day, new Date(dataForDay.date)))
                if (visDataForTimeFrame === undefined){
                    timeframe.visOnDay = null
                    return
                }
                timeframe.visOnDay = visDataForTimeFrame.parking_vision_traces ?? null
            })
        })
        setCameraJobs(camJobsNew)
        return camJobsNew
    }

    async function fillStayData(oldCamJobs: CameraJob[]){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){ return }
        console.log("Getting stay metrics for cameras...")
        const camIds: string[] = params.camIds
        const startDate: Date = params.start
        const endDate: Date =  params.end
        const visApiReq = await Api.getCVStatsByCameras(camIds, startDate, endDate, "stays")
        const stayProcessingJson = await visApiReq.json()
        let stayProcessingData: CVDataForDay[] = stayProcessingJson.stay_processing_stats
        const stayDomainsRaw = stayProcessingData.filter((stayData) => stayData.domain != null)
        const stayDomains: Set<string> = new Set(stayDomainsRaw.map((stayData) => stayData.domain!))
        // stayProcessingData = stayProcessingData.filter((stayData) => stayData.domain === "raw")
        let camJobsWithStay: CameraJob[] = [...oldCamJobs]
        camJobsWithStay.forEach((job) => {
            const stayDataForCam:CVDataForDay[] = stayProcessingData.filter((data) => data.camera_uuid === job.camera.uuid)
            if (stayDataForCam === undefined){
                job.timeFrames.forEach((timeframe) => timeframe.staysOnDay = null)
                return
            }
            job.timeFrames.forEach((timeframe) => {
                const stayDataForTimeFrame = stayDataForCam.filter((dataForDay) => isSameDay(timeframe.day, new Date(dataForDay.date)))
                if (stayDataForTimeFrame === undefined || stayDomains.size === 0 || stayDataForTimeFrame.length === 0){
                    timeframe.staysOnDay = null
                    return
                }

                stayDomains.forEach((stayDomain) => {
                    const staysOnDayForDomain: CVDataForDay | undefined = stayDataForTimeFrame.find((dataForDay) => dataForDay.domain === stayDomain)
                    if (staysOnDayForDomain){
                        if (!timeframe.staysOnDay){
                            timeframe.staysOnDay = []
                        }
                        const stayTimeFrameVal: StayTimeFrameVal = {
                            domain: stayDomain,
                            stayCount: staysOnDayForDomain.stays ?? 0
                        }
                        timeframe.staysOnDay.push(stayTimeFrameVal)
                    }
                })
            })
        })
        setCameraJobs(camJobsWithStay)
        return camJobsWithStay
    }

    async function fillTaskData(oldCamJobs: CameraJob[]){
        const params = getCvProcessingParams()
        if (params.selectedCams.length === 0){ return }
        console.log("Getting task metrics for cameras...")
        const camIds: string[] = params.camIds
        const startDate: Date = params.start
        const endDate: Date =  params.end
        const taskApiReq = await Api.getCVStatsByCameras(camIds, startDate, endDate, "tasks")
        const taskProcessingJson = await taskApiReq.json()
        const taskProcessingData: CVDataForDay[] = taskProcessingJson.task_processing_stats
        let camJobsWithTasks: CameraJob[] = [...oldCamJobs]
        camJobsWithTasks.forEach((job) => {
            const taskDataForCam:CVDataForDay[] = taskProcessingData.filter((data) => data.camera_uuid === job.camera.uuid)
            if (taskDataForCam === undefined){
                job.timeFrames.forEach((timeframe) => timeframe.tasksOnDay = null)
                return
            }
            job.timeFrames.forEach((timeframe) => {
                const dataForTimeFrame = taskDataForCam.find((dataForDay) => isSameDay(timeframe.day, new Date(dataForDay.date)))
                if (dataForTimeFrame === undefined){
                    timeframe.tasksOnDay = null
                    timeframe.completedTasksOnDay = null
                    return
                }
                timeframe.tasksOnDay = dataForTimeFrame.all_tasks ?? null
                timeframe.completedTasksOnDay = dataForTimeFrame.completed_tasks ?? null

            })
        })
        setCameraJobs(camJobsWithTasks)
        return camJobsWithTasks
    }

    function updateJob(newJob: CameraJob){
        const jobCopy = [...cameraJobs]
        let newJobs = jobCopy.filter((job) => (job.camera.uuid != newJob.camera.uuid))
        newJobs.push(newJob)
        newJobs.sort(((d1,d2) => d1.camera.name < d2.camera.name ? -1 : 1));
        setCameraJobs(newJobs)
    }

    function updateCamJobs(newJobs: CameraJob[]){
        const jobCopy = [...newJobs]
        setCameraJobs(jobCopy)
    }

    async function runJobs(runConfigParams: CVRunPanelConfig){
        // if (runState == "running"){
        //     alert("Run already in progress!")
        //     return
        // }
        // if (runState != "starting"){
        //     return
        // }
        // setRunState("running")
        const timelinesToRun = getTimelinesToBeRun(cameraJobs)
        if (timelinesToRun.length === 0){
            return
        }
        const camerasToRun: CameraJob[] = cameraJobs
        const config: CVRunPanelConfig = runConfigParams
        for (let i = 0; i < camerasToRun.length; i++) {
            const job = camerasToRun[i];
            const timeFramesToRun = job.timeFrames.filter((timeframe) => timeframe.selected)
            for (let j = 0; j < timeFramesToRun.length; j++) {
                const timeframe = timeFramesToRun[j]
                //STAY GEN PROCESS
                if (config.stayGenConfig.selected){
                    job.currJobTask = "stay gen"
                    job.currJobProgress = (j / job.timeFrames.length) * 100
                    timeframe.currTask = "stay gen"
                    updateCamJobs(cameraJobs)
                    const laneIds = job.lanesToInclude.map((lane) => lane.uuid)
                    const stayGenResp = await Api.runStayGenerator(laneIds, timeframe.day, config.stayGenConfig.domain)
                    if (stayGenResp.status === 201){
                        const stayGenJson = await stayGenResp.json()
                        const staysGenerated: number = stayGenJson.stays_generated
                        timeframe.stayGenResp = "success"
                    }else{
                        const stayGenText = await stayGenResp.text()
                        alert(stayGenText)
                        timeframe.stayGenResp = `failure|${stayGenText}`
                    }
                }
                //TASK GEN PROCESS
                if (config.taskGenConfig.selected){
                    job.currJobTask = "task gen"
                    job.currJobProgress = (j / job.timeFrames.length) * 100
                    timeframe.currTask = "task gen"
                    updateCamJobs(cameraJobs)
                    const laneIds = job.lanesToInclude.map((lane) => lane.uuid)
                    const taskGenResp = await Api.runTaskGenerator(laneIds, timeframe.day, config.taskGenConfig.domain,
                        config.taskGenConfig.ommit_single_frame_stays,
                        config.taskGenConfig.only_single_frame_stays
                    )
                    if (taskGenResp.status === 201){
                        const taskGenJson = await taskGenResp.json()
                        // const tasksGenerated: number = taskGenJson.tasks_created
                        timeframe.taskGenResp = "success"
                    }else{
                        const taskGenText = await taskGenResp.text()
                        alert(taskGenText)
                        timeframe.taskGenResp = `failure|${taskGenText}`
                    }
                }
                //MATCHMAKER PROCESS
                if (config.matchmakerConfig.selected){
                    job.currJobTask = "matchmaker"
                    job.currJobProgress = (j / job.timeFrames.length) * 100
                    timeframe.currTask = "matchmaker"
                    updateCamJobs(cameraJobs)
                    const laneIds = job.lanesToInclude.map((lane) => lane.uuid)
                    const matchmakerResp = await Api.runMatchmaker(laneIds, timeframe.day,
                        config.matchmakerConfig.inputDomain,
                        config.matchmakerConfig.outputDomain,
                        config.matchmakerConfig.add_default_starts,
                        config.matchmakerConfig.add_default_ends
                    )
                    if (matchmakerResp.status === 200){
                        // const tasksGenerated: number = taskGenJson.tasks_created
                        timeframe.taskGenResp = "success"
                    }else{
                        const matchmakerText = await matchmakerResp.text()
                        alert(matchmakerText)
                        timeframe.taskGenResp = `failure|${matchmakerText}`
                    }
                }

                // if (config.parkingVisConfig.selected) {
                //     let tz = "America/Chicago"
                //     const startOfDay = DateTime.fromJSDate(timeframe.day).setZone(tz, {keepLocalTime: true}).startOf("day").toUTC();
                //     const endOfDay = DateTime.fromJSDate(timeframe.day).setZone(tz, {keepLocalTime: true}).endOf("day").toUTC();
                //     const traceResp = await Api.getTracesByTime(job.camera.uuid, startOfDay, endOfDay)
                //     const traceJson = await traceResp.json()
                //     const traces: ITrace[] = traceJson.traces
                //     if (traces.length === 0){
                //         timeframe.parkingVisionResp = "success|no-traces"
                //         updateCamJobs(camerasToRun)
                //     }
                //     for (let z = 0; z < traces.length; z++) {
                //         const trace = traces[z];
                //         const parkingVisionReq = await Api.runParkingVision(trace, job.camera)
                //         if (parkingVisionReq.status === 201){
                //             timeframe.parkingVisionResp = "success"
                //         }else if (parkingVisionReq.status === 409){
                //             timeframe.parkingVisionResp = "success|duplicate"
                //         }else{
                //             const parkingVisionJson = await parkingVisionReq.json()
                //             timeframe.parkingVisionResp = `failure|${JSON.stringify(parkingVisionJson)}`
                //         }
                //         updateCamJobs(camerasToRun)
                //     }
                // }
            }
        }
        console.log("completed jobs!")
        setRunState(undefined)
        generateJobRows()

    }

    if (!deplsLoaded){
        return (
            <Card>
                <Card.Title>Loading...</Card.Title>
                <Spinner/>
            </Card>
        )
    }


    return <Container fluid>
        <Row>
            <Col className="bg-light" sm={2}>
                <Card>
                    <Card.Title>Camera Picker</Card.Title>
                </Card>
                <CVControllerTable deployments={allDepls}
                                   selectedCameraIds={selectedCamIds}
                                   setCamIdsFunction={setSelectedCamIds}
                                   selectedLaneIds={selectedLaneType}
                />
            </Col>
            <Col sm={10}>
                <Row>
                    <Col sm={9}>
                        <DatePicker  value={dayRange} onChange={setDayRange} />
                    </Col>
                    <Col>
                        <LaneTypeSelector selectedLaneTypes={Array.from(selectedLaneType)}
                                          allLanes={allLanes}
                                          setFunc={setSelectedLaneType}/>
                    </Col>
                </Row>
                <Container fluid style={{maxHeight: "65vh", overflowY: "scroll",
                                        minHeight: "50vh"}}>
                    <CVMainTable cameraRowJobs={cameraJobs} updateJobFunc={updateCamJobs}/>
                </Container>
                <br/>
                <CVRunPanel config={runConfig}
                            setConfigFunc={setRunConfig}
                            setStatusFunc={setRunState}
                            progress={runProgress}
                            camJobs={cameraJobs}
                            runFunction={runJobs}
                />
            </Col>
        </Row>
    </Container>
}
